<template>
  <div class="MonitorOverviewResponseTimeChart" v-if="loaded">
    <div class="card">
      <div class="card-body pt-2">
        <div class="d-flex justify-content-between align-items-center">
          <div class="title" :style="{ marginTop: !showActions ? '20px' : '' }">Response Time, ms</div>
          <div
            v-if="showActions"
            class="d-flex justify-content-end align-items-center"
          >
            <base-button @click="$emit('open-modal')"
                         class="screenshot-button">📸</base-button>
            <filter-location :show-label="false"
                             :monitor="monitor"
                             :default-continent="defaultContinent"
                             :default-date-from="defaultDateFrom"
                             :continents="continents"
                             :locations="locations"
                             @updatePeriod="dateFrom = $event"
                             @updateContinent="currentContinent = $event"
                             @updateDefaultDateFrom="defaultDateFrom = $event"
                             @updateLocation="updateLocation" />
          </div>
        </div>

        <template v-if="hasEnoughData">
          <apexchart type="line"
                     height="400"
                     :options="chartOptions"
                     :series="seriesList"
                     class="chart"
          />
        </template>

        <base-alert v-else>The chart will be shown after an hour until we collect some data</base-alert>

      </div>
    </div>
  </div>
</template>

<script>
import monitorApi from '@/api/monitorsApi.js'
import VueApexCharts from 'vue-apexcharts'
import FilterLocation from '@/components/Common/Filters/FilterLocation/FilterLocation.vue'
import BaseAlert from '@/components/Base/BaseAlert.vue'

import dayjs from 'dayjs'
import moment from 'moment'

const emptyStateRange = 60 * 60 // An hour

export default {
  components: {
    BaseAlert,
    apexchart: VueApexCharts,
    FilterLocation
  },

  props: {
    monitor: {
      required: true,
      type: Object
    },

    user: {
      required: true,
      type: Object
    },

    showActions: {
      type: Boolean,
      default: true
    },
    series: {
      type: Array,
      default: () => []
    },
    isOpenModal: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      seriesList: [],
      chartOptions: {
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          },
          showForSingleSeries: true
        },

        chart: {
          id: 'mainChart',
          stacked: false,
          animations: {
            enabled: false
          },

          toolbar: {
            autoSelected: 'pan',
            show: false
          }
        },

        dataLabels: {
          enabled: false
        },

        markers: {
          size: 0
        },

        stroke: {
          width: 2,
          curve: 'smooth'
        },

        xaxis: {
          type: 'datetime',
          labels: {
            rotate: 0,
            style: {
              fontFamily: 'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
            }
          }
        },

        yaxis: {
          min: 0,
          forceNiceScale: true,

          labels: {
            style: {
              fontFamily: 'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif'
            }
          }
        },

        tooltip: {
          enabled: this.showActions,
          x: {
            format: 'dd MMMM hh:mm:ss TT'
          }
        }
      },

      seriesSmall: [],
      hasEnoughData: true,

      loaded: false,

      defaultContinent: this.user.preferences.ResponseTimeFilter ? JSON.parse(this.user.preferences.ResponseTimeFilter).continent : null,
      currentContinent: null,
      dateFrom: null,
      dateTo: null,
      defaultDateFrom: this.user.preferences.ResponseTimeFilter ? JSON.parse(this.user.preferences.ResponseTimeFilter).dateFrom : 'month',
      since: dayjs().subtract(7, 'day').startOf('day').unix(),
      till: dayjs().endOf('day').unix(),

      continents: [],
      snapshots: [],
      locations: [],
      allSnapshots: {}
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      this.allSnapshots = await this.loadNodeCategories()
      const snapshotKeys = Object.keys(this.allSnapshots)
      this.locations = snapshotKeys

      this.loaded = true

      let min = this.since
      let max = this.till

      if (max - min < emptyStateRange) {
        this.hasEnoughData = false
        return
      }

      this.serializeSnapshots(Object.values(this.allSnapshots), snapshotKeys)
    },

    updateLocation (locations) {
      const updatedSnaps = Object.entries(this.allSnapshots).reduce((acc, [key, value]) => {
        if (locations.includes(key)) {
          acc[key] = value
        }
        return acc
      }, {})

      this.serializeSnapshots(Object.values(updatedSnaps), Object.keys(updatedSnaps))
    },

    serializeSnapshots (snapshots, titles) {
      const series = snapshots.map((snap, index) => ({
        name: titles[index],
        data: snap.map(({ date, time_total }) => [
          date * 1000,
          Math.ceil(time_total * 1000)
        ])
      }))
      this.$store.commit('setSeries', series)
    },
    async loadNodeCategories () {
      const data = await monitorApi.getNodeCategories(this.monitor.id, {
        dateFrom: this.dateFrom || moment().format('YYYY-MM-DD')
      })
      this.dateTo = data ? data['dateTo'] : null
      this.continents = [...Object.keys(data['snapshots'])]
      if (!this.defaultContinent) {
        this.defaultContinent = this.continents[0]
      }
      return data['snapshots'][this.currentContinent || this.defaultContinent] ?? []
    }
  },

  watch: {
    series (newValue) {
      this.seriesList = newValue
    },
    isOpenModal () {
      if (this.isOpenModal) {
        this.seriesList = [...this.series]
      }
    },
    currentContinent () {
      this.init()
      monitorApi.storeResponseTimeFilters({ continent: this.currentContinent, dateFrom: this.defaultDateFrom })
    },

    dateFrom (newValue) {
      this.init()
      this.$emit('updateDateFrom', { 'dateFrom': newValue, 'dateTo': this.dateTo })
    },

    dateTo (newValue) {
      this.init()
      this.$emit('updateDateFrom', { 'dateFrom': this.dateFrom, 'dateTo': newValue })
    },

    defaultDateFrom () {
      monitorApi.storeResponseTimeFilters({ continent: this.currentContinent ?? this.defaultContinent, dateFrom: this.defaultDateFrom })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart ::v-deep{
  .apexcharts-legend {
    justify-content: flex-start !important;
    padding: 13px 0 0 22px !important;
    gap: 7px 0;

    &-text {
      margin-right: 10px;
    }
  }
}

.MonitorOverviewResponseTimeChart {
  box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

  .card-body {
    padding: 15px;
  }

  .title {
    max-height:44px;
    margin-left: 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.019em;
    color: #828282;
  }

  .screenshot-button {
    width: 50px;
    height: 44px;
    margin-right: 10px;
    background-color: transparent;
    border: 1px solid #E9EBEC;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

    &:not(:disabled):hover {
      background-color: transparent;
    }
  }
}
</style>
